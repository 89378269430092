export const TEXT_PERSON_MORALE =
  "En cliquant, je donne mon accord pour que la monnaie \"{EU-}\" devienne utlisable quand [\u00A0{VALIDATOR}\u00A0] validateur(s) (personne physique) auront validé cette création de monnaie. Je reconnais que les [\u00A0{COST}\u00A0] EU-coins versés pour initier cette création de monnaie ne me seront pas remboursés en cas d'echec, c'est à dire si le quota de [\u00A0{VALIDATOR}\u00A0] validateur(s) n'est jamais atteint";

export const TEXT_CREATION =
  "En cliquant, je donne mon accord pour que la monnaie \"{EU-}\" devienne utilisable quand [\u00A0{VALIDATOR}\u00A0] validateur(s) (personne physique) auront validé cette création de monnaie. Je reconnais que les {COST} versés pour initier cette création de monnaie ne me seront pas remboursés en cas d'échec, c'est-à-dire si le quota de [\u00A0{VALIDATOR}\u00A0] validateur(s) n'est jamais atteint";

export const TEXT_PERSON_PHYSIQUE_VALIDATION =
  "En cliquant, je donne mon accord pour que le montant de \"{COST}\" versé pour valider cette création de monnaie ne me sera pas remboursé en cas d’échec, c’est à dire si le quota des validateur(s) n’est jamais atteint.";

export const TEXTÈ_CONVERSION_AFFINITAIRE =
  "En cliquant, je donne mon accord pour que mon compte soit débité du montant choisi en \"{EU-}\" et crédité du même montant en \"{EU-}\". Je note que l'opération inverse est possible en cas d'erreur";

export const TEXTÈ_CONVERSION_COIN =
  "En cliquant, je donne mon accord pour que mon compte soit débité du montant choisi en \"{EU-}\" et crédité du même montant en \"{EU-}\". Je note que l'opération inverse est possible en cas d'erreur";

export const TEXT_VALIDATION_MORAL_IMPOSSIBLE = 
  "Seules des personnes physiques peuvent engager la validation d'une nouvelle monnaie. La validation d'une monnaie n'est pas possible par les personnes morales."